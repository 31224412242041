'use client';

import { useSession } from 'next-auth/react';
import { useTranslations } from 'next-intl';
import { ReactNode, useEffect, useMemo } from 'react';
import { getRouteSignIn } from '@/shared/const/router';
import { useAppNavigation } from '@/shared/lib/hooks/useAppNavigation/useAppNavigation';
import { useAppPathname } from '@/shared/lib/router/navigation';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

export const SessionChildren = ({ children }: { children: ReactNode }) => {
  const pathname = useAppPathname();
  const { data: session, status }: { data: any; status: string } = useSession();
  const { push } = useAppNavigation();
  const t = useTranslations();

  const logoutPage = useMemo(() => pathname === getRouteSignIn(), [pathname]);

  useEffect(() => {
    if (!logoutPage)
      if (session?.error === 'ErrorRefresh') {
        showSnackbar(t('Your session has expired'), 'success');
        push({ path: getRouteSignIn(), withParams: true });
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoutPage, session, session?.error]);

  return children;
};
