import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CookiesConfirmationSchema,
  TConfirmKeys,
  TConfirmVariants,
} from '../types/cookiesConfirmation';

const initialState: CookiesConfirmationSchema = {
  analytics: 'denied',
  marketing: 'denied',
  necessary: 'denied',
  preferences: 'denied',
};

export const cookiesConfirmationSlice = createSlice({
  initialState,
  name: 'cookiesConfirmation',
  reducers: {
    resetAll: state => {
      state.necessary = 'denied';
      state.marketing = 'denied';
      state.analytics = 'denied';
      state.preferences = 'denied';

      localStorage.setItem(
        'consentMode',
        JSON.stringify({
          ad_storage: 'denied',
          analytics_storage: 'denied',
          functionality_storage: 'denied',
          personalization_storage: 'denied',
          security_storage: 'denied',
        }),
      );
    },
    setAllCookies: state => {
      state.necessary = 'granted';
      state.marketing = 'granted';
      state.analytics = 'granted';
      state.preferences = 'granted';

      localStorage.setItem(
        'consentMode',
        JSON.stringify({
          ad_storage: 'granted',
          analytics_storage: 'granted',
          functionality_storage: 'granted',
          personalization_storage: 'granted',
          security_storage: 'granted',
        }),
      );
    },
    setConfirmation: (
      state,
      action: PayloadAction<{
        confirmKey: TConfirmKeys;
        value: TConfirmVariants;
      }>,
    ) => {
      const { confirmKey, value } = action.payload;
      state[confirmKey] = value;

      localStorage.setItem(
        'consentMode',
        JSON.stringify({
          ad_storage: state.marketing,
          analytics_storage: state.analytics,
          functionality_storage: state.necessary,
          personalization_storage: state.preferences,
          security_storage: state.necessary,
        }),
      );
    },
  },
});

export const {
  actions: { setConfirmation, resetAll, setAllCookies },
} = cookiesConfirmationSlice;
export const { reducer: cookiesConfirmationReducer } = cookiesConfirmationSlice;
