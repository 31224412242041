import { useTranslations } from 'next-intl';
import { ChangeEvent, FC, memo, useCallback } from 'react';
import ReactGA from 'react-ga4';
import cls from './cookiesModal.module.css';
import { getCookiesConfirmation } from '../../model/selectors/getCookiesConfirmation';
import {
  resetAll,
  setConfirmation,
} from '../../model/slices/cookiesConfirmationSlice';
import {
  CookiesConfirmationSchema,
  TConfirmKeys,
} from '../../model/types/cookiesConfirmation';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { Button, Checkbox } from '@/shared/ui/Buttons';
import { Flex, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

interface ICookiesModalProps {
  className?: string;
  setOpenCookiesModal: (val: boolean) => void;
  setViewCookiesBanner: (val: boolean) => void;
}

export const CookiesModal: FC<ICookiesModalProps> = memo(props => {
  const { className, setOpenCookiesModal, setViewCookiesBanner } = props;

  const dispatch = useAppDispatch();
  const { isMobile } = useDetectDevice();
  const cookiesConfirmation = useAppSelector(getCookiesConfirmation);
  const t = useTranslations();

  const toCansel = useCallback(() => {
    dispatch(resetAll());
    setOpenCookiesModal(false);
    localStorage.removeItem('consentMode');
  }, [dispatch, setOpenCookiesModal]);

  const toSavePreferences = useCallback(() => {
    const initialState: CookiesConfirmationSchema = {
      analytics: 'denied',
      marketing: 'denied',
      necessary: 'denied',
      preferences: 'denied',
    };

    const localConsent =
      localStorage.getItem('consentMode') || JSON.stringify(initialState);

    setOpenCookiesModal(false);
    setViewCookiesBanner(false);

    ReactGA.gtag('consent', 'update', JSON.parse(localConsent));
  }, [setOpenCookiesModal, setViewCookiesBanner]);

  const onCheckbox = useCallback(
    (confirmKey: TConfirmKeys, event: ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setConfirmation({
          confirmKey,
          value: event.target.checked ? 'granted' : 'denied',
        }),
      );
    },
    [dispatch],
  );

  return (
    <VStack className={classNames(cls['cookies-modal'], {}, [className])}>
      <Text variant='h3' as='h3'>
        {t('Cookie Settings')}
      </Text>
      <Text variant='body-m' className={cls.text}>
        {t('Cookies text')}
      </Text>
      <Text variant='subheading'>{t('Manage Consent Preferences')}</Text>
      <VStack className={cls.checkboxes} gap='m'>
        <Checkbox
          checked={cookiesConfirmation.necessary === 'granted'}
          id='necessary'
          onChange={event => onCheckbox('necessary', event)}
        >
          <Text variant='body-m'>{t('Necessary')}</Text>
        </Checkbox>
        <Checkbox
          checked={cookiesConfirmation.marketing === 'granted'}
          id='marketing'
          onChange={event => onCheckbox('marketing', event)}
        >
          <Text variant='body-m'>{t('Marketing')}</Text>
        </Checkbox>
        <Checkbox
          checked={cookiesConfirmation.analytics === 'granted'}
          id='analytics'
          onChange={event => onCheckbox('analytics', event)}
        >
          <Text variant='body-m'>{t('Analytics')}</Text>
        </Checkbox>
        <Checkbox
          checked={cookiesConfirmation.preferences === 'granted'}
          id='preferences'
          onChange={event => onCheckbox('preferences', event)}
        >
          <Text variant='body-m'>{t('Preferences')}</Text>
        </Checkbox>
      </VStack>
      <Flex
        max
        direction={isMobile ? 'column' : 'row'}
        gap={isMobile ? 'm' : '4-x-l'}
        className={cls.buttons}
      >
        <Button
          fullWidth
          text={t('cancel')}
          typeButton='primary-outline'
          onClick={toCansel}
        />
        <Button
          fullWidth
          text={t('confirm')}
          typeButton='accent-solid'
          onClick={toSavePreferences}
        />
      </Flex>
    </VStack>
  );
});
