import { useTranslations } from 'next-intl';
import { FC, memo, useCallback, useMemo } from 'react';
import ReactGA from 'react-ga4';
import cls from './cookiesBanner.module.css';
import {
  resetAll,
  setAllCookies,
} from '../../model/slices/cookiesConfirmationSlice';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { Button } from '@/shared/ui/Buttons';
import { Flex, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

interface ICookiesBannerProps {
  className?: string;
  openCookiesModal: boolean;
  setOpenCookiesModal: (val: boolean) => void;
  setViewCookiesBanner: (val: boolean) => void;
}

export const CookiesBanner: FC<ICookiesBannerProps> = memo(props => {
  const {
    className,
    openCookiesModal,
    setOpenCookiesModal,
    setViewCookiesBanner,
  } = props;

  const { isMobile, isDesktop } = useDetectDevice();
  const dispatch = useAppDispatch();
  const t = useTranslations();

  const toToggleOpen = () => {
    setOpenCookiesModal(!openCookiesModal);
  };

  const toRejectAll = useCallback(() => {
    dispatch(resetAll());
    setViewCookiesBanner(false);
    setOpenCookiesModal(false);
  }, [dispatch, setViewCookiesBanner, setOpenCookiesModal]);

  const toAllCookies = useCallback(() => {
    const initialState = {
      ad_storage: 'granted',
      analytics_storage: 'granted',
      functionality_storage: 'granted',
      personalization_storage: 'granted',
      security_storage: 'granted',
    };

    const localConsent =
      localStorage.getItem('consentMode') || JSON.stringify(initialState);

    dispatch(setAllCookies());

    setViewCookiesBanner(false);

    ReactGA.gtag('consent', 'update', JSON.parse(localConsent));
  }, [dispatch, setViewCookiesBanner]);

  const gapCookieBanner = useMemo(() => (isMobile ? 'm' : 'x-l'), [isMobile]);
  const alignCookieBanner = useMemo(
    () => (isDesktop ? 'center' : 'start'),
    [isDesktop],
  );
  const directionCookieBanner = useMemo(
    () => (isDesktop ? 'row' : 'column'),
    [isDesktop],
  );

  const gapButtonsBlock = useMemo(() => (isMobile ? 'x-s' : 's'), [isMobile]);
  const directionButtonsBlock = useMemo(
    () => (isMobile ? 'column-reverse' : 'row'),
    [isMobile],
  );

  return (
    <Flex
      className={classNames(cls['cookies-banner'], {}, [className])}
      justify='between'
      gap={gapCookieBanner}
      direction={directionCookieBanner}
      align={alignCookieBanner}
    >
      <VStack gap='x-x-s'>
        <Text variant='h5' as='h5' className={cls.text}>
          {t('We use cookies')}
        </Text>
        <Text variant='body-m' className={cls.text}>
          {t('Cookies text')}
        </Text>
      </VStack>
      <Flex
        direction={directionButtonsBlock}
        gap={gapButtonsBlock}
        className={cls.buttons}
      >
        <Button
          fullWidth
          text={t('Allow All')}
          typeButton='accent'
          size='small'
          className={cls['all-btn']}
          onClick={toAllCookies}
        />
        <Button
          fullWidth
          text={t('Manage cookies')}
          typeButton='accent-outline'
          size='small'
          className={cls['manage-btn']}
          onClick={toToggleOpen}
        />
        <Button
          fullWidth
          text={t('Reject all')}
          typeButton='accent-outline'
          size='small'
          className={cls['reject-btn']}
          onClick={toRejectAll}
        />
      </Flex>
    </Flex>
  );
});
