'use client';

import {
  AbstractIntlMessages,
  NextIntlClientProvider,
  useLocale,
} from 'next-intl';
import { FC, ReactNode } from 'react';
import { AppSessionProvider } from './AppSessionProvider/AppSessionProvider';
import { GTMProviderProvider } from './GTMProvider/GTMProvider';
import { GoogleAnalyticsProvider } from './GoogleAnalyticsProvider/GoogleAnalyticsProvider';
import { HotjarProvider } from './HotjarProvider/HotjarProvider';
import { LanguageProvider } from './LanguageProvider/LanguageProvider';
import { StoreProvider } from './StoreProvider';
import { ThemeProvider } from './ThemeProvider';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useTheme } from '@/shared/lib/hooks/useTheme/useTheme';
import { useAppPathname } from '@/shared/lib/router/navigation';
import { pathWithHeader } from '@/shared/lib/router/pathWithHeader/pathWithHeader';

interface IRootProvidersProps {
  children?: ReactNode;
  locale: string;
  messages: AbstractIntlMessages;
}

export const RootProviders: FC<IRootProvidersProps> = props => {
  const { children, locale, messages } = props;

  const { theme } = useTheme();
  const pathname = useAppPathname();
  const lang = useLocale();

  const isLocal = process.env.NEXT_PUBLIC_APP_ENV === 'local';
  const widgetKey = process.env.NEXT_PUBLIC_WIDGET_KEY;

  const withHeader = pathWithHeader(pathname);
  const appStyle = { justifyContent: withHeader ? 'center' : 'flex-start' };

  return (
    <body className={classNames('', {}, [theme])} style={appStyle}>
      <StoreProvider>
        <ThemeProvider>
          <NextIntlClientProvider locale={locale} messages={messages}>
            <LanguageProvider locale={locale}>
              <GoogleAnalyticsProvider>
                <GTMProviderProvider>
                  <HotjarProvider>
                    <AppSessionProvider>{children}</AppSessionProvider>
                  </HotjarProvider>
                </GTMProviderProvider>
              </GoogleAnalyticsProvider>
            </LanguageProvider>
          </NextIntlClientProvider>
        </ThemeProvider>
      </StoreProvider>
      {!isLocal && (
        <script
          async
          id='ze-snippet'
          src={`https://static.zdassets.com/ekr/snippet.js?key=${widgetKey}`}
          crossOrigin='anonymous'
        />
      )}
    </body>
  );
};
