import axios from 'axios';
import { Session } from 'next-auth';
import { getSession } from 'next-auth/react';
import { addLanguageHeaderExceptWhiteListForAxios } from '../lib/api/addLanguageHeader';
// eslint-disable-next-line path-checher-ulbi-example/layer-imports

const count = 0;

const api = process.env.NEXT_PUBLIC_API_URL;
interface ISession extends Session {
  accessToken: string;
}

type TSessionValue = ISession | null;

const app = axios.create({
  baseURL: api,
  headers: {
    Accept: 'application/json, text/plain, text/event-stream, */*',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

app.interceptors.request.use(
  async config => {
    const getNewAxiosConfigWithLanguageHeader =
      addLanguageHeaderExceptWhiteListForAxios(config);

    return getNewAxiosConfigWithLanguageHeader;
  },
  error => Promise.reject(error),
);

app.interceptors.request.use(async request => {
  const session: TSessionValue = (await getSession()) as TSessionValue;

  if (session) request.headers.Authorization = `Bearer ${session?.accessToken}`;

  return request;
});

app.interceptors.response.use(
  config => config,
  async error =>
    // if (error?.response?.status === 401 && count < 2) {
    //   count++;

    //   if (count < 2) {
    //     showSnackbar('Your session has expired', 'success');
    //     localStorage.clear();
    //     sessionStorage.clear();
    //     sessionStorage.removeItem('persist:root');
    //     localStorage.removeItem('persist:root');
    //     signOut();
    //   }
    // }
    Promise.reject(error),
);

export default app;
