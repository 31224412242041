'use client';

import { SessionProvider } from 'next-auth/react';
import { ReactNode } from 'react';
import { SessionChildren } from './SessionChildren';

export const AppSessionProvider = ({ children }: { children: ReactNode }) => (
  <SessionProvider
    refetchOnWindowFocus
    refetchInterval={0}
    refetchWhenOffline={false}
  >
    <SessionChildren>{children}</SessionChildren>
  </SessionProvider>
);
