'use client';

import { getCookie } from 'cookies-next';
import { FC, useCallback, useLayoutEffect } from 'react';
import { CountryForEnglishLang } from './model/const/countryForEnglishLang';
import { setOneFieldDataUser } from '@/entities/User';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { useAppNavigation } from '@/shared/lib/hooks/useAppNavigation/useAppNavigation';
import { useAppPathname } from '@/shared/lib/router/navigation';
import { ILanguageProviderProps } from '@/shared/types/langTypes';
import { Spinner } from '@/shared/ui/Loaders';

export const LanguageProvider: FC<ILanguageProviderProps> = props => {
  const { children, locale } = props;
  const dispatch = useAppDispatch();

  const country = getCookie('x-your-custom-geo');
  const pathname = useAppPathname();

  const isLocal = process.env.NEXT_PUBLIC_APP_ENV === 'local';
  const { replace } = useAppNavigation();

  const acceptRequest = !isLocal && !localStorage.getItem('i18nextLng');

  const getLocationRequest = useCallback(async () => {
    if (!localStorage.getItem('i18nextLng')) {
      const currentLang =
        country && country in CountryForEnglishLang ? 'en' : 'ru';
      localStorage.setItem('i18nextLng', currentLang);

      replace({ options: { locale: currentLang }, path: pathname });
    } else {
      console.log('error language provider');

      localStorage.setItem('i18nextLng', 'ru');
    }
  }, [country, pathname, replace]);

  country && dispatch(setOneFieldDataUser({ geolocation: country }));

  useLayoutEffect(() => {
    acceptRequest && getLocationRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!country && !localStorage.getItem('i18nextLng'))
    return (
      <Spinner
        style={{ left: '50%', position: 'absolute', top: '50%' }}
        align='center'
      />
    );

  return children;
};
