'use client';

import { configureStore, Reducer, ReducersMapObject } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
import { createReducerManager } from './reducerManager';
import { StateSchema, ThunkExtraArg, TStore } from './stateSchema';
import {
  packageReducer,
  packagesAllowedReducer,
  packagesPAReducer,
} from '@/entities/Package';
import { subscriptionReducer } from '@/entities/Subscription';
import { transactionsPAReducer, userReducer } from '@/entities/User';
import app from '@/shared/api/api';
import { rtkApi } from '@/shared/api/rtkApi';
import { cookiesConfirmationReducer } from '@/widgets/CookiesConfirmation';
import { formPaymentReducer } from '@/widgets/FormPayment';

const createNoopStorage = () => ({
  getItem(_key: any) {
    return Promise.resolve(null);
  },
  removeItem(_key: any) {
    return Promise.resolve();
  },
  setItem(_key: any, value: any) {
    return Promise.resolve(value);
  },
});

const storage =
  typeof window !== 'undefined'
    ? createWebStorage('local')
    : createNoopStorage();

export function createReduxStore(
  initialState?: DeepPartial<StateSchema>,
  asyncReducers?: ReducersMapObject<StateSchema>,
) {
  const isLocal =
    process.env.NEXT_PUBLIC_APP_ENV === 'local' ||
    process.env.NEXT_PUBLIC_APP_ENV === 'dev' ||
    process.env.NEXT_PUBLIC_APP_ENV === 'stage';

  const rootReducer: ReducersMapObject<StateSchema> = {
    ...asyncReducers,
    cookiesConfirmation: cookiesConfirmationReducer,
    formPayment: formPaymentReducer,
    packages: packageReducer,
    packagesAllowed: packagesAllowedReducer,
    packagesPA: packagesPAReducer,
    subscription: subscriptionReducer,
    transactionsPA: transactionsPAReducer,
    user: userReducer,
    [rtkApi.reducerPath]: rtkApi.reducer,
  };

  const reducerManager = createReducerManager(rootReducer);

  const persistedReducer = persistReducer(
    {
      key: 'root',
      storage,
      whitelist: [
        'packages',
        'user',
        'formPayment',
        'partnersResetPassword',
        'userSignIn',
      ],
    },
    // @ts-ignore
    reducerManager.reduce as Reducer<CombinedState<StateSchema>>,
  );

  const extraArg: ThunkExtraArg = { api: app };

  const store = configureStore({
    devTools: isLocal,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        immutableCheck: !isLocal,
        serializableCheck: false,
        thunk: { extraArgument: extraArg },
      }).concat(rtkApi.middleware),
    preloadedState: initialState,
    reducer: persistedReducer,
  }) as TStore;

  store.reducerManager = reducerManager;
  const persist = persistStore(store);

  return { persist, store };
}
